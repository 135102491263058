.select {
  max-height: 30px;
  width: 100%;
  max-width: 440px;
}

.primary {
  min-width: 300px;
}

.secondary {
  min-width: 300px;
}

.tertiary {
  width: 180px;
}

.quartery {
  width: 200px;
}
