.container {
  width: 100%;
  height: 100%;
}

.leftChild {
  width: 347px;
  position: relative;
  z-index: 2;
}
