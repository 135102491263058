.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background-color: rgba(76, 100, 117, 0.4);
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  z-index: 10;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}

.modalContent {
  width: 65vw;
  min-width: 1200px;
  //height: 70vh;
  //min-height: 670px;
  max-height: 90vh;
  background-color: var(--aqua900);
  //overflow: auto;
}

.modal.show {
  opacity: 1;
  pointer-events: visible;
}

.subModal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 30;
  overflow: auto;
  background-color: rgba(76, 100, 117, 0.4);
  backdrop-filter: blur(3px);
  visibility: visible;
  opacity: 1;
}

.header {
  background-color: var(--primaryColor);
  display: flex;
  justify-content: space-between;
  padding-left: 27px;
  padding-right: 12px;
  padding-top: 7px;
  padding-bottom: 7px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  margin-bottom: 7px;
  gap: 5px;
}

.subModalContent {
  background-color: var(--aqua900);
  overflow: auto;
}
