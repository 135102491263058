.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  cursor: pointer;
  border-radius: 24px;
  width: 30px;
  height: 30px;
}
