
.customCursor {
  cursor: pointer ;
}

.table {
  max-height: calc(100vh - 148px);
}

.wrapper {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid var(--darkBlue800);
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner {
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background-color: var(--darkBlue800);
}

.courierContainer {
  padding-top: 15px;
  padding-left: 20px;
  min-width: 1100px;
  width: 100%;
}

.courierTd {
  padding: 10px 5px;
  border-bottom: solid var(--secondaryColor);
  text-wrap: nowrap;
}

.mapContainer {
  height: 880px;
}

.filtersRow {
  position: relative;
  z-index: 1000000;
}

.markerPopup {
  & ul li {
    margin: 6px 0;
  }
}
