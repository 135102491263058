@use "reset/reset";
@use "variables/variables";
@use "fonts/fonts";
@use "normalize/normalize";
@use "scrollbar/scrollbar";
@use "utils/utils";
@use "global/global";
@use "leafletMap/leaflet_map";
@use "contextMenu/contextMenu";

@tailwind base;
@tailwind components;
@tailwind utilities;
