.container {
  overflow-x: auto;
}

.cell {
  border-right: 1px solid var(--aqua700);
  border-bottom: 1px solid var(--aqua700);
}
.stickyHeader {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--aqua400); // Фон заголовков, чтобы они перекрывали ячейки
}
