.container {
  height: 63px;
  display: flex;
  width: 100%;
  overflow: auto;
  padding-bottom: 300px;
  margin-bottom: -300px;
  box-sizing: unset;
  -ms-overflow-style: none;
  scrollbar-width: none;
  justify-content: space-between;

  &::-webkit-scrollbar {
    display: none;
  }
}

.fixedContainer {
  //overflow: visible;
}

.header {
  min-width: fit-content;
  width: 100%;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  border-bottom: solid var(--primaryColor);
}

.filters {
  border-bottom: solid var(--secondaryColor);
  padding: 14px 20px;
}

.tabs {
  display: flex;
  gap: 8px;
}

.activeTab {
  background-color: #3C4145;
  color: white;
  padding: 4px 15px;
  border-radius: 10px;
  cursor: pointer;
}

.tab {
  padding: 4px 15px;
  border-radius: 10px;
  background-color: #F9F9F9;
  cursor: pointer;
}
