.wrapper {
  min-width: 20px;
  min-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: inline;
}

/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-image: url("../../assets/icons/radio_unchecked.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-image: url("../../assets/icons/radio_checked.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  background-image: url("../../assets/icons/radio_unchecked.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}
