.switchLabel {
  display: flex;
  align-items: center;
  width: 42px;
  height: 22px;
  border-radius: 20px;
  border: solid var(--primaryColor);
  position: relative;
  cursor: pointer;
}

.switchButton {
  background-color: var(--primaryColor);
  width: 16px;
  height: 16px;
  border-radius: 9px;
  position: absolute;
  transition: 0.2s;
  left: 2px;
}

.switchLabelActive {
  background-color: var(--primaryBlue);
}

.switchCheckbox:checked + .switchLabel .switchButton {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.switchCheckbox {
  width: 0;
  height: 0;
  display: hidden;
}
