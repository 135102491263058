.primary {
  height: 30px;
  border-radius: 22px;
  border-color: var(--primaryColor);
  border-width: 1px;
  text-indent: 40px;
  background-color: var(--white);
  text-indent: 10px;

  &::placeholder {
    color: var(--primaryColor);
  }
}

.secondary {
  height: 30px;
  border-radius: 22px;
  border-color: var(--primaryColor);
  border-width: 1px;
  text-indent: 40px;
  background-color: var(--white);
  text-indent: 10px;

  &::placeholder {
    color: var(--primaryColor);
  }
}

.tertiary {
  height: 42px;
  border-radius: 22px;
  border-color: var(--grey);
  border-width: 1px;
  text-indent: 40px;
  background-color: var(--white);

  &::placeholder {
    color: var(--primaryColor);
  }
}
