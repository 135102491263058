@font-face {
  font-family: "MontRegular";
  color: var(--regularTextColor);
  src: url("../../assets/fonts/Montserrat-Regular.ttf");
  font-size: 16;
}

@font-face {
  font-family: "MontMedium";
  color: var(--regularTextColor);
  src: url("../../assets/fonts/Montserrat-Medium.ttf");
  font-size: 16;
}

@font-face {
  font-family: "MontLight";
  color: var(--regularTextColor);
  src: url("../../assets/fonts/Montserrat-Light.ttf");
  font-size: 16;
}

@font-face {
  font-family: "MontBold";
  color: var(--regularTextColor);
  src: url("../../assets/fonts/Montserrat-Bold.ttf");
  font-size: 16;
}

@font-face {
  font-family: "MontSemiBold";
  color: var(--darkBlue900);
  src: url("../../assets/fonts/Montserrat-SemiBold.ttf");
  font-size: 25;
}

@font-face {
  font-family: "MontMediumItalic";
  color: var(--darkBlue900);
  src: url("../../assets/fonts/Montserrat-MediumItalic.ttf");
  font-size: 18;
}

@font-face {
  font-family: "MontSemiBoldItalic";
  color: var(--darkBlue900);
  src: url("../../assets/fonts/Montserrat-SemiBoldItalic.ttf");
  font-size: 16;
}
