.topRow{
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  gap: 12px;
  padding: 12px 30px;
}

.select {
  width: 40px;
}

.cell {
  cursor: default;
}

.modalGridWrapper {
  padding: 0 4px;
  height: 500px;
  overflow-y: auto;
}

.bottomRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  padding: 30px 30px 0 0;
}
