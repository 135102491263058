.tooltip {
  position: absolute;
  top: 0;
  left: 0;
  width: 280px;
  height: 280px;
  border-radius: 24px;
  background-color: var(--white);
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}
