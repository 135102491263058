$spinner-size: 60px;
$spinner-bg: rgba(83, 134, 233, 0.2);
$spinner-color: rgba(83, 134, 233, 1);
$rotate-speed: 800ms;

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $spinner-size;
  height: $spinner-size;
  border-radius: 50%;
  border: ($spinner-size / 8) solid $spinner-bg;
  border-bottom-color: $spinner-color;
  animation: rotateSpinner $rotate-speed linear infinite;
}

@keyframes rotateSpinner {
  to {
    transform: rotate(360deg);
  }
}
