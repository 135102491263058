/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  scrollbar-width: thin;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 6px;
  width: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primaryColor);
  border-radius: 10px;
  width: 10px;
}
