.topRow {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  gap: 8px;
  padding: 12px;
  justify-content: center;
}

.select {
  width: 40px;
}

.cell {
  cursor: default;
}

.bottomRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  padding: 30px 30px 0 0;
}

.inputWrapper {
  position: relative;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
}

.input {
  background-color: var(--mainBackgroundColor);
  padding: 4px 38px 4px 15px;
  border-radius: 24px;
  //max-width: 157px;

}

.icon {
  position: absolute;
  top: 8px;
  right: 18px;
}
