.container {
  min-width: 760px;
  max-height: 580px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  margin-top: 120px;
}

.logo {
  max-width: 126px;
  max-height: 126px;
}
