.wrapper {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid var(--darkBlue800);
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner {
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background-color: var(--darkBlue800);
}

.table {
  max-height: 400px;
  overflow-y: auto;
}

.orderTd {
  padding: 10px 4px;
  border-bottom: solid var(--secondaryColor);
}

.activeTd {
  background-color: var(--darkBlue700);
}
