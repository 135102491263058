// Global styles

@-ms-viewport {
  //width: device-width;
}

.app-container {
  display: flex;
  grid-template-columns: 1fr 1620px;
  overflow: hidden;
  height: 100%;
  min-height: 630px;
  //max-width: 1920px;
  //max-height: 1080px;
}

.outlet-container {
  overflow: hidden;
  flex: 1;
}

/*html {
  overflow: hidden;
}

#zoom-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
#zoom-content {
  transform-origin: 0 0;
  height: 100vh;
  overflow: visible;
}*/
