.container {
  max-width: 347px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: end;
  min-height: 75px;
  padding: 16px 6px;
}

.table {
  max-height: calc(100vh - 140px);
}

.td {
  padding: 10px 4px;
  border-bottom: solid var(--secondaryColor);
}

.active {
  background-color: var(--darkBlue700);
}

.courierContainer {
  padding-top: 15px;
  padding-left: 20px;
  min-width: 1100px;
  width: 100%;
}

.courierTd {
  padding: 10px 5px;
  border-bottom: solid var(--secondaryColor);
  text-wrap: nowrap;
}

.mapContainer {
  height: 880px;
}

.shortHeader {
  margin-bottom: -100px;
  padding-bottom: 100px;
}
