.baseLayout {
  display: flex;
  height: 100%;
  transition: max-width 0.3s;
}

.leftChild {
  border-right: solid var(--primaryColor);
}

.rightElement {
  flex: 1;
  overflow: hidden;
  padding: 0 15px;
}

.filters {
  box-sizing: content-box;
  min-height: 5rem;
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: auto;
  padding-bottom: 300px;
  margin-bottom: -300px;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
