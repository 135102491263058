.listContainer {
  height: 100%;
}

.orderTd {
  padding: 10px 4px;
  border-bottom: solid var(--secondaryColor);
}

.activeTd {
  background-color: var(--darkBlue700);
}

.assignContainer {
  max-width: 438px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: end;
  min-height: 75px;
  padding: 16px 6px;
  gap: 0.5rem;
}

.table {
  max-height: calc(100vh - 250px);
}

.filterRow {
  overflow-x: auto;
  padding-bottom: 300px;
  margin-bottom: -300px;
  box-sizing: unset;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-top: 2px;

  &::-webkit-scrollbar {
    display: none;
  }}
