.wrapper {
}

.pressableSimple {
  cursor: pointer;
  transition: opacity .2s;

  &:hover {
    opacity: .9;
  }
  &:active {
    opacity: .7;
  }
}

.pressableActiveOnly {
  cursor: pointer;
  transition: opacity .2s;

  &:active {
    opacity: .7;
  }
}

.backgroundTransition {
  transition: background-color .2s;
}

html {
  overflow: hidden;
}

.noBorder {
  border: 0 !important;
}

.borderBottom {
  border-bottom: solid var(--primaryColor);
}


/*#root {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#zoom-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

#zoom-content {
  transform: scale(0.9); !* Adjust scale as needed *!
  transform-origin: top left;
  height: 111%; !* Compensate for scaling *!
}*/

#zoom-container {
  //width: 100%;
  //height: 100vh;
  //overflow: hidden;
}

#zoom-content {
  min-width: 2018px;
  transform-origin: top left;
}

#root {
  height: 100%;
}
