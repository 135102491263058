.inputWrapper {
  position: relative;
  max-height: 30px;
  border-radius: 24px;
  max-width: 157px;
}

.input {
  background-color: var(--mainBackgroundColor);
  padding: 4px 38px 4px 15px;
  border-radius: 24px;
  max-width: 157px;

  &::placeholder {
    font-size: 16px;
    font-family: var(--regularFontFamily);
    color: var(--darkBlue800);
  }
}

.icon {
  position: absolute;
  top: 8px;
  right: 18px;
}
