.tableWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  flex: 1;
  max-height: calc(100vh - 140px);
  overflow: scroll;
}

.table {
  width: 100%;
  flex: 1;
  overflow: scroll;

  tr {
    width: 100%;
  }

  th {
    text-align: start;
  }

  .head {
    text-align: start;
    background-color: var(--aqua400);

    th {
      padding: 10px 4px;
    }
  }
}

.borderTertiary {
  border-top: solid var(--primaryColor);
  border-bottom: solid var(--primaryColor);
}
