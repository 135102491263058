.gap {
  gap: 10px;
}

.primary {
  background-color: var(--aqua600);
  display: flex;
  align-items: center;
  max-height: 30px;
  padding: 10px 14px;
  border-radius: 24px;
  align-items: center;

  span {
    font-size: 16px;
    color: var(--white);
  }
}

.secondary {
  display: flex;
  max-height: 30px;
  padding: 10px 14px;
  align-items: center;
  border: solid var(--primaryColor);
  border-radius: 24px;

  span {
    color: var(--darkBlue900);
  }
}

.blue {
  display: flex;
  border-radius: 24px;
  max-height: 30px;
  padding: 10px 14px;
  align-items: center;
  background-color: var(--blue400);

  span {
    color: var(--white);
  }
}

.red {
  display: flex;
  border-radius: 24px;
  max-height: 30px;
  padding: 10px 14px;
  align-items: center;
  background-color: var(--red800);

  span {
    font-size: 12px;
    color: var(--white);
  }
}

.tertiary {
  background-image: linear-gradient(
    var(--gradientColor1),
    var(--gradientColor2)
  );
  min-width: 175px;
  min-height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 22px;

  span {
    color: var(--white);
    user-select: none;
  }
}

.quartery {
  display: flex;
  max-height: 30px;
  padding: 10px;
  align-items: center;

  span {
    color: var(--primaryColor);
  }
}

.green {
  background-color: var(--green900);
  display: flex;
  max-height: 30px;
  padding: 10px 14px;
  border-radius: 24px;
  align-items: center;

  span {
    color: var(--white);
  }
}
.darkBlue {
  display: flex;
  justify-content: center;
  background-color: var(--primaryColor);
  border-radius: 24px;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  max-width: 94px;
}


.loader {
  width: 20px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 3px solid lightblue;
  border-right-color: white;
  animation: l2 1s infinite linear;
  margin-right: 5px;
}
@keyframes l2 {to{transform: rotate(1turn)}}

