.accordion {
  transition: max-height .4s;
}

.expanded {
  max-height: 100px;
}

.collapsed {
  max-height: 0 !important;
  overflow: hidden;
}
