.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.tabWrapper {
  margin: 0px 3px 3px 3px;
  border: solid var(--mainBackgroundColor);
  flex: 1;
  border-top: solid var(--primaryColor);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 100%;
}

.tabs {
  display: flex;
  transform: translateY(2px);
  margin-left: 3px;
}

.tabNameContainer {
  position: relative;
  min-width: 107px;
  height: 30px;
  border-top: solid var(--mainBackgroundColor);
  border-left: solid var(--mainBackgroundColor);
  border-right: solid var(--mainBackgroundColor);
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  span {
    padding: 5px 10px;
    color: var(--aqua200);
  }
}

.tabActive {
  border-top: solid var(--primaryColor);
  border-left: solid var(--primaryColor);
  border-right: solid var(--primaryColor);
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  span {
    color: var(--primaryColor);
  }
}

.separator {
  position: absolute;
  z-index: 100;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: var(--aqua900);
  height: 3px;
}
