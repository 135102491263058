.todoContainer {
  position: relative;
  overflow-y: visible;
}

.photoContainer {
  position: absolute;
  top: -10px;
  left: 50px;
  width: 250px;
  height: 270px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.photo {
  width: 250px;
  height: 250px;
  object-fit: contain;
}

.hovered .photoContainer {
  opacity: 1;
}
