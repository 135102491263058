.container {
  width: 300px;
  height: 100%;
  background-color: var(--primaryColor);
  //overflow-y: auto;
  transition: width 0.3s;
  display: flex;
  flex-direction: column;

  img {
    user-select: none;
  }
  div #logo2 {
    max-width: 75px;
  }
}

.collapseButton {
  background-color: var(--primaryColor);
  position: absolute;
  top: 18px;
  right: -15px;
  width: 15px;
  height: 50px;
  border-top-right-radius: 22px;
  border-bottom-right-radius: 22px;
  z-index: 1000000000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
}

.closedButton {
  top: -3px;
}

.minimized {
  width: 60px;
}

.iconOpen {
  transform: rotate(180deg);
}

.iconClose {
  transform: rotate(0deg);
}

.arrowCollapsed {
  transform: rotate(0deg);
}

.arrowExpanded {
  transform: rotate(180deg);
}

.outlineStyle:hover {
}

.menuButton {
  cursor: pointer;
  transition: border-color .2s, opacity .1s;

  &:hover {
    border-color: var(--blue800);
  }
  &:active {
    opacity: .9;
  }
}

.navWrapper {
  //overflow-y: auto;
}

.leftNavHeader {
  border-width: 0;
  border-bottom-width: 1px;
  height: 78px;
  padding-left: 16px;
}

.logo {
  height: 52px;
}

.logoText {
  font-family: var(--semiBoldFontFamily);
  font-size: 24px;
  color: white;
  margin-left: 8px;
  white-space: nowrap;
}

.minimizedItemContainer {
  background-color: var(--aqua900);
  padding: 13px 0;
  border-radius: 8px;
  position: absolute;
  min-width: 100px;
  min-height: 100px;
  background-color: white;
}

.menuChildrenWhenLeftBarFull {
  padding: 7px 0 7px 14px;
}

.menuChildrenItem {
  position: relative;

  &:hover {
    text-decoration: underline;
  }
}

.menuChildrenItemArrow {
  position: absolute;
  left: -11px;
  top: 7px;
  opacity: 1;
  animation: menuSmallArrowAppear;
  animation-duration: .3s;
}

.menuArrow {
  transition: all .3s;
}

@keyframes menuSmallArrowAppear {
  from {
    opacity: 0;
    left: -14px
  }
  to {
    opacity: 1;
    left: -11px
  }
}
