.for {
  background-color: rgba(0, 0, 0, 0.38);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  height: 100vh;
}

.modal {
  width: 100%;
  max-width: 800px;
  min-width: 200px;
  background-color: white;
  border-radius: 8px;
}


.model__header {
  background-color: var(--primaryColor);
  display: flex;
  justify-content: space-between;
  padding-left: 27px;
  padding-right: 12px;
  padding-top: 7px;
  padding-bottom: 7px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  margin-bottom: 7px;
  gap: 5px;
  color: white;
}


.container {
  display: flex;
  justify-content: center;
}

.card {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #BABABA;
  padding: 0 10px;
}

.statusList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.statusItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-weight: 500;
}

