.dialog {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background-color: rgba(76, 100, 117, 0.4);
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  z-index: 20;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}

.dialogContent {
  overflow: visible;
  position: relative;
  width: 432px;
  height: 250px;
  background-color: var(--aqua900);
  background-color: var(--white);
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.show {
  opacity: 1;
  pointer-events: visible;
}

.closeIcon {
  position: absolute;
  cursor: pointer;
  right: -50px;
  top: -50px;
  z-index: 21;
  overflow: visible;
}
