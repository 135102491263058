.photoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  border: solid var(--mainBackgroundColor);
  width: 240px;
  height: 240px;
  overflow: hidden;
}

.photo {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
}
