:root {
  // Colors
  --mainBackgroundColor: #c0daed;
  --white: #ffffff;
  --grey: #cacaca;
  --grey100: #b2c1cc;
  --grey200: #e7eff6;
  --primaryColor: #658399;
  --secondaryColor: #bac7d0;
  --gradientColor1: #181818;
  --gradientColor2: #535353;
  --aqua200: #8599a7;
  --aqua300: #adbfcc;
  --aqua400: #edf7ff;
  --aqua500: #e2ebf2;
  --aqua600: #2698f0;
  --aqua700: #cde8fb;
  --aqua800: #a3b9c9;
  --aqua900: #f6fbff;
  --green900: #06ba00;
  --darkBlue900: #153750;
  --darkBlue800: #27465d;
  --darkBlue700: #93cdf5;
  --primaryBlue: #2a9bec;
  --blue400: #90cbf5;
  --blue800: #2b9bec;
  --red800: #f42d2e;

  // Text Colors
  --errorTextColor: #f42e2e;
  --regularTextColor: #3a3a45;

  // Font families
  --regularFontFamily: "MontRegular";
  --boldFontFamily: "MontBold";
  --mediumFontFamily: "MontMedium";
  --semiBoldFontFamily: "MontSemiBold";
  --lightFontFamily: "MontLight";
  --montMediumItalic: "MontMediumItalic";
  --semiBoldItalic: "MontSemiBoldItalic";

  // React Contextify
  --contexify-zIndex: 666;
  --contexify-menu-minWidth: 220px;
  --contexify-menu-padding: 15px;
  --contexify-menu-radius: 6px;
  --contexify-menu-bgColor: #fff;
  --contexify-menu-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1),
    2px 4px 4px rgba(0, 0, 0, 0.1), 3px 6px 6px rgba(0, 0, 0, 0.1);
  --contexify-menu-negatePadding: var(--contexify-menu-padding);

  --contexify-separator-color: rgba(0, 0, 0, 0.2);
  --contexify-separator-margin: 5px;
  --contexify-itemContent-padding: 6px;
  --contexify-activeItem-radius: 4px;
  --contexify-item-color: #333;
  --contexify-activeItem-color: #fff;
  --contexify-activeItem-bgColor: #a7d4f3;
  --contexify-rightSlot-color: #6f6e77;
  --contexify-activeRightSlot-color: #fff;
  --contexify-arrow-color: #6f6e77;
  --contexify-activeArrow-color: #fff;
}
